#menu {
    padding: 10px 0 0;
    text-align: right;
    justify-content: flex-end;
    .primaryMenu-collapse{
    justify-content: flex-end;
        ul.row { 
            padding-left: 15px;
            justify-content: flex-end;
            li{
                list-style: none;
                a{
                    text-transform: uppercase;   
                    padding-left: 15px;
                }
            }
        }
    }
}



/* --------- Sous-Menu / open Bootstrap 4 dropdown on hover --------- */
@media only screen and (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}


button.primaryMenu-toggler{border:1px solid $primary; padding: 5px;background: #fff}

@mixin burger-size($size) {
  #burger {
    width: 1.25rem * $size;
    height: 1rem * $size;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    margin: 0 auto;

  }

  #burger span {
    display: block;
    position: absolute;
    height: 0.2rem * $size;
    width: 100%;
    background: $primary;
    border-radius: 15px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  #burger span:nth-child(1) {
    top: 0rem;
    transform-origin: left center;
  }

  #burger span:nth-child(2) {
    top: 0.4rem * $size;
    transform-origin: left center;
  }

  #burger span:nth-child(3) {
    top: 0.8rem * $size;
    transform-origin: left center;
  }

  #burger.open span:nth-child(1) {
    transform: rotate(45deg);
    top: -0.05rem * $size;
    left: 0.2rem * $size;
  }

  #burger.open span:nth-child(2) {
    opacity: 0;
  }

  #burger.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 0.82rem * $size;
    left: 0.2rem * $size;
  }
}

@include burger-size(1.5);


@media(max-width:991px){
    
    header button.primaryMenu-toggler{ 
        position: absolute;
        top: 0px;
        right: 15px;
        display: block;
    }
    
    ul.row{
        display: block;
        padding: 30px 15px;
        li{
            margin: 10px 0;
            a{font-size: 1.3rem;}
        }
    }
    
    #menu{width: 100%; padding: 0;
        .primaryMenu-link{
            text-align: right;
            justify-content: end;
            padding-right: 15px;
            font-size: 1.2rem;
            
        }
        .dropdown-menu{
            border:none;
            border-right: 1px solid #888;
        }

    }
}

