footer {
  background: #353a44;
  padding: 20px 0;
 color:#fff;
}

#footer{ 
    text-align: center;
    .row{justify-content: space-between;
        ul{margin:0;text-align: right;
          li {
            list-style: none;
            float: right;
            margin-right: 15px;  
          }

          a {
            color: #fff;
            text-decoration: none;
          }
       }     
    }
}

#footer-admin a {
  color: #000;
  text-decoration: none;
}

#footer-menu {
  a {
    font-size: 0.9em;

    &:hover {
      color: grey;
    }
  }

  li.active a {
    color: grey;
  }
}

#list-footer {
  list-style: none;

  li {
    margin: 0 10px 0 0;
  }

  a {
    &#administration {
      /* si picto */
      /* display: block; text-indent: -9999px; background: url(../img/pictos.png) no-repeat 0 -31px transparent; width: 28px; height: 28px; */
    }

    &#mentions {
      /* si picto */
      /* display: block; text-indent: -9999px; background: url(../img/pictos.png) no-repeat 0 -31px transparent; width: 28px; height: 28px; */

      cursor: pointer;
      background-position: 0 0;
    }
  }
}

/* pictos en png */

#footer-realisation a {
  img {
    border: 0;
  }

  &:hover {
    background: none;
  }
}
