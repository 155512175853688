header {
    background: $bgcHeader;
    padding: 10px 0 ;
    box-shadow: 0 0 30px rgba(0,0,0,0.10)  ;  
    .tel-top{
        justify-content: end;
        z-index: 1000;
        position: relative;
        margin-bottom: -15px;
        a.tel-header{
            font-weight: 900;
            color:$secondary;
            font-size: 20px;
        }
    }  
}

#logo {
  img {
    max-width: 100%;
    height: auto;
    min-width: 100px;
  }
}


@media(max-width:991px){
    
    header .tel-top{
        justify-content: center;
        margin: auto;
        padding-top: 10px;
        width: 150px;
        margin-bottom: -31px;
    }
    
    
    
}
@media(max-width:768px){
    
    header .tel-top{
        padding:0;
        margin-bottom: -24px;
        a.tel-header{font-size: 15px;
    margin-left: 45px;}
    }
    
    
    
}