//partials
@import './front/base/appfront';
@import './front/vendors/swiper';
@import './front/home/diaporama';

// si widget actu activé
@import './front/widgets/actu-widget';
@import './front/widgets/webimmo-widget';

// si widget galerie activé
@import './front/widgets/galerie-widget';

// si widget recrutement activé
@import './front/widgets/recrutement-widget';

// si widget catalogue activé
@import './front/widgets/catalogue-widget';

h2{
    font-size: 45px;
    font-weight: 900;
    text-transform: inherit;
}

blockquote{
    text-align: center;
    font-family: 'Satisfy';
    border: none;
    font-size: 28px;
    color:#52565e;
}

.fixe{
    position: fixed;
    right: 0;
    z-index: 1000;
    width: 60px;
    height: 50px;
    border-radius: 50px 0 0 50px;
    padding: 8px 10px 5px 15px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.15);
    a{width: 20px}
}

#mailfixed.fixe{
    top: 300px;
    background: #fcb040;
}
#telfixed.fixe{
    top: 360px;
    background: #eb1862;
}

#intro{
    background: url("/img/dots-1.svg") no-repeat left center;
    background-size: 140px;
    .row{
        align-items: center;
        max-width: 1680px;
        margin: 20px auto;
        h1{ 
            position: relative;
            font-size: 100px;
            font-weight: 900;
            color:$primary;
            margin-left: 5%;
            text-transform: inherit;  
            z-index: 1000;
            background: #fff;  
            padding: 10px;
            span.first{
                font-size: 31px;
                display:block;
                font-weight: 400;

                margin-bottom: -10px;
            }
            span.second{
                font-size: 25px;
                font-weight: 700;
                color:$pink;
                text-transform: uppercase;
                display:block;
            }
        }
        .chiffres{
            div{

                text-align: center;
                strong.counter{
                    font-size: 50px;
                    font-weight: 900;
                    color:$secondary;
                }
                span{
                    font-size: 20px;
                    font-weight: 700;
                    color:$primary;
                    &.block{display: block; }
                    &.big{font-weight: 900;font-size: 25px; }
                }
            }   
        }
    }
}

#projet{
    padding-top: 80px;
    background: url("/img/circle.svg") no-repeat center top;
    background-size: 470px;
    .baseline-projet{
        font-size: 20px;
        color:$pink;
        text-transform: uppercase;
        font-weight: 700;
    }
    .line-exp{ 
        justify-content: center;
        .block-exp{
            background: #fff;
            box-shadow: 0 0 20px rgba(0,0,0,0.15);
            border-radius: 30px;
            text-align: center;
            margin: auto;
            max-width:180px;
            padding: 20px 0 10px;
            img{max-width:70px;}
            h3{font-size: 20px;}
        }
    }    
    #accompagnement{
        background:#f7f7f7 url("/img/dots-2.svg") no-repeat right center;
        background-size: 200px;
        padding: 50px 0;
        margin-top: 70px;
        h3{
            font-size: 20px;
            font-weight: 700;
            text-transform: uppercase;
            color:$pink;
        }
        .border-l{
            border-left:4px solid $purple;
            text-align: left;
            margin: 10px auto;
            background: rgba(247, 247, 247, 0.8);
            h4{font-size: 20px;}
        }
    }
}


#agence{
    padding: 150px 0 80px;
        .row{
            align-items: center;
            img{max-width: 450px;}
            h2{font-size: 35px;}
            .agence-baseline{font-size: 30px;margin: -15px 0 20px;}
        }
}





/*REFERENCES*/

.main-content {
    padding:1%;
    width:96vw;
    height: 100%;
    margin: 20px auto;
 
    p{font-size: 20px;}


    .portfolio {
      width: 100%;
      margin:50px auto; 
      display:grid;
      grid-template-columns: 30vmax 30vmax 30vmax;
      grid-template-rows: repeat(2, 40vmin);

      grid-gap: 1vh;
      justify-content: center;
      align-content: center;
    /*    grid-auto-flow: dense; */
    }

    a.portfolio-item{
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0px 27px 36px -31px rgba(0,0,0,0.75);
      border-radius: 3px;
    }

    .portfolio-text{
        position: absolute;
        top: 0; bottom: 0; right: 0; left: 0;
        opacity: 0;
        text-align: center;
        background: rgba(0,0,0,.6);
        color: #fff;
        transition: 0.4s ease-out; 
    }

    .portfolio-text img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 120px;
        margin-left: -60px;
    }



    a.portfolio-item:hover .portfolio-text{ opacity: 1;}
    a.portfolio-item:hover .title-gal{ opacity: 0;}


    .title-gal {
        position: absolute;
        bottom: 5%;
        background: rgba(0,0,0,.45);
        border: 1px solid #fff;
        color: #fff;
        height: 80px;
        width: 90%;
        left: 5%;
        text-align: center;

    }

    .title-gal.tgright {  bottom:3%;}


    .title-gal h3{color:#fff;}

    .portfolio-text img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 120px;
        margin-left: -60px;
    }



    .sq-1 {
      background-image: url("/img/my-decoupe-metal.jpg");
      background-color: rgba(0, 0, 0, 0.3);
      grid-column-end: 2;
    }

    .rec-port-sm{
      background-image: url("/img/france-bebe.jpg");
      background-color: rgba(0, 0, 0, 0.3);
      grid-column-start: 2;
    }

    .rec-land-lg-2{
      background-image: url("/img/km-nautisme-1.jpg");   
      background-color: rgba(0, 0, 0, 0.3);
      grid-column: 1 / 3;
      grid-row-end: auto;
    /*   width:51vh; */
    }

    .rec-port-lg{
      background-image: url("/img/terra-libra.jpg");
      background-color: rgba(0, 0, 0, 0.3);
      grid-column-start: 3;
      height:81vmin;
    }

} 

#form{
    position: relative;
    .formulaire{
        margin:40px auto;
        background: #f7f7f7;
        border: 1px solid #cdcdcd;
        border-radius: 30px;
        padding: 30px;
        z-index: 200;
        h2{text-align: center;margin-bottom: 40px;}
        .champs{
            display: inline-block;
            vertical-align: middle;
            width: 30%;
            margin: 0 1%;
            border-bottom: 1px solid #cdcdcd;
            &.contact-message{
                width: 96%;
                margin: 40px auto 0;
                padding: 0 15px;
                textarea{height:100px;}
            }
        }
        input[type=date], input[type=time], input[type=datetime-local], input[type=week], input[type=month], input[type=text], input[type=email], input[type=url], input[type=password], input[type=search], input[type=tel], input[type=number], optgroup, select, textarea, .form-control {
            border:none;
            border-radius: 0px;
            width: 100%;
            padding: 0.5rem 0;
            background-color: transparent;
        }
        .required::-webkit-input-placeholder{ color: #353a44;}
        .required::-moz-placeholder{ color: #353a44;}
        .required:-ms-input-placeholder{ color: #353a44;}
        .required:-moz-placeholder{ color: #353a44;}
        .mandatory {
            width: 0;
            height: 0;
            border: 0;
        }
        .alert-info {
            color: #b3b3b3 !important;
            background-color: transparent !important;
            border-color: transparent !important;
        } 
        em{font-size: 15px;}
        
        
    }
    .bg{
        z-index: 100;
        position: absolute;
        width: 140px;
        opacity: 0.2;
        top: 50%;
        transform: translateY(-50%)}
    .bg-l{left: 0;}
    .bg-r{right: 0;}
}



@media(max-width:1300px){
    #intro .row h1 {font-size: 80px;
    span.first{ font-size: 30px;}
    span.second{ font-size:19px;}
    }
    #agence {padding:100px 0 40px;}
} 

@media(max-width:1080px) {
   /* .container {  width: 90%;}*/
    .main-content .portfolio{grid-template-columns: 30vmin 30vmin 30vmin;padding: 0 30px;}
}


@media(max-width:900px){
    #intro .row h1 {font-size: 60px;
    span.first{ font-size: 18px;}
    span.second{ font-size: 16px;}
    }
    h2{font-size: 35px;} 
}

@media(max-width:767px){
    #projet .line-exp > div{
        padding: 5px;
        .block-exp h3{font-size: 16px;}
    } 
    #agence {
        padding: 40px 0;
        .row .agence-img{
            margin: auto;
            text-align: center;
                img{
                    max-width: 300px;
                }
        }
    }
    
    #intro .row .chiffres div{
            strong.counter{ font-size: 35px;}
            span{font-size: 16px;}

    }
}

@media(max-width:680px){
    #intro .row h1 {font-size: 50px;
    span.first{ font-size: 18px;}
    span.second{ font-size: 14px;}
    }
    .main-content .portfolio{ display: block;}
    .main-content a.portfolio-item {
        display: block;
        width: 100%;
        height: 250px;
        margin:3vw auto;
        grid-column: 1 / 4;
    }
    h2{font-size: 30px;}
    
}

@media(max-width:576px){
    #intro {background: none;
        .row h1 {
        margin: 0;
        text-align: center;
        font-size: 50px;
        background: transparent;
            span.first{ font-size: 20px;}
            span.second{ font-size: 16px;}
        }
    }
    #projet .line-exp .block-exp{margin-bottom: 15px;}
    .fixe { width: 50px;height: 40px;padding: 3px 4px 5px 10px;}
    #telfixed.fixe { top: 360px;}

}

@media(max-width:425px){
    header{ position: fixed; z-index: 10001; width: 100%;top: 0;    padding: 15px 0 10px; }
    #content{ margin-top: 60px; }
    #intro .row h1 {font-size: 40px;
    span.first{padding-bottom: 10px; font-size: 18px;}
    span.second{ font-size: 16px;}
    }
    h2{font-size: 25px;}
    #form .formulaire{
        border-radius: 0;
        .champs, .champs.contact-message
        {width: 100%;display: block;margin: 15px  auto;padding: 0;}
    }
}








