.product {
    padding-bottom: 15px;
    padding-top: 15px;
    text-align: center;

    $parent: &;

    &-inner {
        transition: all, .2s;
    }

    &-link {
        color: $colorText;

        &:hover #{$parent}-img {
            opacity: .5;
        }

        > * {
            display: block;
            margin-top: .5em;
            margin-bottom: .5em;
        }
    }

    &-imgContainer {
        background-color: $primary;
        display: inline-block;
    }

    &-img {
        transition: opacity .2s;
    }

    &-nophoto {
        padding-left: 15px;
        padding-right: 15px;
        display: block;
        text-align: center;

        &:hover {
        }
    }

    &-about {
        display: block;
        height: 100%;
    }

    &-name {
        font-weight: 700;
    }

    &-brand {

    }

    &-price {
        color: $primary;
        font-weight: 700;
    }

    &-btns {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-button {
        margin-bottom: .5em;
        margin-top: .5em;
    }

    &-text {
        margin-top: .5em;
    }
}
